<template>
  <div>
    <ServerError v-if="ServerError" />
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="blue"
      spinner="spinner"
    />
    <v-form ref="form">
      <v-layout wrap justify-center>
        <v-flex xs12 lg8 pa-0 pa-sm-6>
          <v-card class="mx-auto elevation-2 pa-6" align="center" outlined>
             <v-layout wrap>
              <v-flex pb-md-2 pl-md-4>
                <h3
                  class="text-left nsbold"
                  style="font-weight: 700; font-size: 20px"
                > 
                  Feedback Generation
                </h3>
              </v-flex>
            </v-layout>
            <v-layout wrap>
                <v-flex xs6 pr-2>
                     <v-layout style="font-weight: 400" wrap>
              <v-flex xs12>
                <v-text-field
                  v-model="user.admissionNo"
                  dense
                  label="Admission No"
                  outlined
                  @change="stdetails()"
                  :rules="[(v) => !!v || 'Admission number is required']"
                ></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  v-model="name"
                  dense
                  disabled
                  label="Name"
                  outlined
                ></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  v-model="classs"
                  dense
                  disabled
                  label="Class"
                  outlined
                  @change="stdetails()"
                  :rules="[(v) => !!v || 'Admission number is required']"
                ></v-text-field>
              </v-flex>
        
              <v-flex xs12>
                <v-text-field
                  v-model="school"
                  dense
                  disabled
                  label="School"
                  outlined
                ></v-text-field>
              </v-flex>
            
            </v-layout>
                </v-flex>
                <v-flex xs6 pl-2>
                    <v-layout style="font-weight: 400" wrap fill-height>
                <v-flex xs12>
                    <v-textarea
                    height="240px"
          outlined
          name="input-7-4"
          label="Feedback"
          hide-details
          v-model="user.feedback"
        ></v-textarea>
                </v-flex>
             
             </v-layout>
                </v-flex>
            </v-layout>
           
             
            <!------------------------------------------------->
               <v-flex xs12 align-self-center class="nsregular mt-2">
              <v-btn
                style="font-size: 14px; font-weight: 400"
                color="#766BC0"
                dark
                block
                depressed
                large
                @click="dialoge = true"
                >SUBMIT
              </v-btn>
            </v-flex>
          </v-card>
          <!---------------dialogue----------------->
          <v-dialog width="400px" v-model="dialoge">
            <v-card width="400px">
              <v-toolbar dark color="#766BC0" dense flat class="body-2">
                <v-toolbar-title> Confirm </v-toolbar-title>
              </v-toolbar>
              <v-card-text class="pa-4 black--text">
                <v-layout wrap>
                  <v-flex xs6> Admission No: {{ user.admissionNo }} </v-flex>
                  <v-flex xs6> Name: {{ name }} </v-flex>
                  <v-flex xs6> Class: {{ classs }} </v-flex>
                  <v-flex xs6> School: {{ school }} </v-flex>
                  <v-flex xs6> Feedback: {{ user.feedback }} </v-flex>
                </v-layout>
              </v-card-text>
              <v-card-actions class="pt-3">
                <v-spacer></v-spacer>
                <v-btn
                  color="grey"
                  text
                  class="body-2 font-weight-bold"
                  @click="dialoge = false"
                  >Cancel</v-btn
                >
                <v-btn
                  color="primary"
                  class="body-2 font-weight-bold"
                  outlined
                  @click="add()"
                  >OK</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-snackbar
            :timeout="1000"
            :value="true"
            right
            color="red"
            v-model="snackbar"
          >
            <span style="font-size: 20px"> {{ msg }}</span>
          </v-snackbar>
        </v-flex>
      </v-layout>
    </v-form>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      dateOnly:null,
      name: null,
      classs: null,
      school: null,
      snackbar: false,
      ServerError: false,
      appLoading: false,
      dialoge: false,
    modal2: false,
      menu: false,
      menu2: false,
      show1: false,
      msg: null,
      stdata: [],
      user: {
        admissionNo: null,
        feedback: null,
        studentid: null,
      },
      rules: {
        required: (value) => !!value || "Required.",
        counter: (value) => value.length <= 20 || "Max 20 characters",
        phoneRules: [
          (v) => !!v || "phone is required",
          (v) =>
            (v && v.length <= 10) || "number must be less than 11 characters",
          (v) =>
            (v && v.length >= 10) ||
            "number must be greater than 10 characters",
          (v) => /^\d{10}$/.test(v) || "phone number must be a number",
        ],
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
      firstcheckbox: false,
      Myname: [],
      refresh2: false,
      refresh1: false,
    };
  },
  computed: {
    passwordMatch() {
      return () => this.password === this.verify || "Password must match";
    },
  },
  // mounted(){
  //   this.stdetails();
  // },
  methods: {
    testStep(window_data) {
      this.user.fromDate = window_data.mod;
      this.dateOnly = this.user.fromDate.slice(0,10)


    },
    // testStep2(window_data) {
    //   this.user.toDate = window_data.mod;
    //   this.user.toDate = this.user.toDate.slice(11,5)
    //   console.log("Win2=", window_data.mod);
    // },
    stdetails() {
      // if (this.$refs.form.validate()) {
      //   this.user.classid = this.myClass;
      axios({
        method: "post",
        url: "/admissionnumber/studentdetails",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          admissionNo: this.user.admissionNo,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.snackbar = true;
            this.msg = response.data.msg;
            this.name = response.data.data.name;
            this.classs = response.data.data.studentclass.className;
            this.school = response.data.data.school.SchoolName;
            this.user.studentid = response.data.data._id
          } else {
            this.snackbar = true;
            this.msg = response.data.msg;
            // if (this.snackbar == false) {
            //   this.$router.go();
            // }

            // setTimeout(() => this.$router.go(),3000)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    add() {
      if (this.$refs.form.validate()) {
        // this.user.studentid = this.id;

        axios({
          method: "post",
          url: "/feedback/add",
          headers: {
            token: localStorage.getItem("token"),
          },
          data: this.user,
        })
          .then((response) => {
            if (response.data.status == true) {
              this.snackbar = true;
              this.msg = response.data.msg;
              this.dialoge = false;
              this.user = {};
              this.$refs.form.resetValidation();
              if (this.snackbar == false) {
                this.$router.go();
              }
              setTimeout(() => this.$router.go(), 3000);
            } else {
              this.snackbar = true;
              this.msg = response.data.msg;
              this.dialoge = false;
              this.user = {};
              this.$refs.form.resetValidation();
              if (this.snackbar == false) {
                this.$router.go();
              }
              setTimeout(() => this.$router.go(), 3000);
            }

            this.refresh2 = true;
            this.refresh1 = true;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
};
</script>
<style scoped>
.v-date-picker {
  color: red !important;
}
</style>